#system-message-container {
    #system-message {
        .alert {
            .close {
                text-decoration : none;
            }

            &.alert-error {
                @extend .bg-danger;
            }
        }
    }
}