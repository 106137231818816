footer#footer {
    background : #0277ba;

    .footer-top {
        background-color : #e6e6e6;
        padding          : 45px 0 25px;

        div.footer-top-container {
            width : 100%;

            div.k2ItemsBlock {
                ul {
                    list-style-type : none;
                    padding         : 0 10px;

                    li {
                        div.moduleItemIntrotext {
                            div.social-button-logo-row {
                                div.social-button-logo-col {
                                    p {
                                        margin-bottom : 0;
                                    }

                                    svg {
                                        font-size    : 40px;
                                        margin-right : 22px !important;
                                        color        : #5b5b5b;
                                        @include media-breakpoint-down(md) {
                                            font-size : 30px;
                                        }
                                    }

                                    img {
                                        position          : relative;
                                        top               : 50%;
                                        -webkit-transform : translateY(-50%);
                                        -ms-transform     : translateY(-50%);
                                        transform         : translateY(-50%);

                                        @include media-breakpoint-down(md) {
                                            width : 100%;
                                        }
                                    }

                                    div.moduletable {
                                        position          : relative;
                                        top               : 50%;
                                        -webkit-transform : translateY(-50%);
                                        -ms-transform     : translateY(-50%);
                                        transform         : translateY(-50%);

                                        div.hwx-social-buttons {
                                            div.hwx-social-buttons-container {
                                                margin : auto;
                                                width  : max-content;

                                                svg {

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div#hwx-logoslider-109 {
                div.hwx-logoslider-static-items {
                    div.item {
                        background-image      : url("../../../images/p4_consult_4c.png");
                        background-repeat     : no-repeat;
                        background-attachment : fixed;
                        background-position   : center;

                    }
                }
            }
        }
    }

    .footer-nav-top {

    }

    .footer-main {
        .footer-left-container {

        }

        .footer-middle-container {

        }

        .footer-right-container {

        }
    }

    .footer-nav-bottom {
        padding : 18px 0;

        ul {
            width  : max-content;
            margin : auto;

            @include media-breakpoint-down(md) {
                display : block;
            }

            li {
                padding : 10px 12px;

                a {
                    text-decoration : none;
                    color           : white;
                }
            }
        }
    }

    .footer-bottom {

    }
}