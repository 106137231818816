#hwx-search {
    #hwx-search-form {
        @include rem(margin-bottom, 50);
        
        label {
            font-weight : $font-weight-bold;
        }
        
        .hwx-search-searchwords {
            .hwx-search-searchwords-send {
                display     : flex;
                align-items : flex-end;
            }
        }
        
        .hwx-search-limit {
            display : none;
        }
    }
    
    #hwx-search-results {
        .hwx-search-result {
            @include rem(margin-bottom, 50);
        }
    }
}