nav.navbar {
    a.navbar-brand {
        padding : 0;
    }

    div.navbar-container {
        position : relative;

        a.nav-link {
            @include font-size-rem($nav-link-font-size);
            text-decoration : none;

            &.home-icon {
                @include rem(font-size, $hwx-menu-home-icon-font-size);
            }
        }

        ul.navbar-nav {
            flex-wrap : nowrap;

            ul {
                border        : none;
                border-radius : 0;

                // Mehrspaltigkeit in Subemenüs
                &[class^="make-nav-cols-"],
                &[class*=" make-nav-cols-"] {
                    @include rem(width, $hwx-menu-multicol-ul-width);

                    .dropdown-item {
                        display         : flex;
                        justify-content : center;
                        align-items     : center;
                    }
                }
            }

            li {
                align-self: center;

                a.nav-link {
                    @include make-link-border;

                    svg,
                    i {
                        @include margin-right(20);
                    }
                }
            }

            .dropdown {
                ul.dropdown-menu {
                    box-shadow : 0 5px 5px 0 rgba($black, 0.3);
                }
            }

            .dropup {
                .scroll-up,
                .scroll-down {
                    display : none !important;
                }

                ul.dropdown-menu {
                    box-shadow    : 0 0 5px 0 rgba($black, 0.3);
                    margin-bottom : 0;
                }
            }

            @include media-breakpoint-up(md) {
                & > .dropup {
                    & > a.nav-link {
                        .sub-arrow {
                            border-top    : 0;
                            border-bottom : .3em solid;
                        }
                    }
                }
            }
        }
    }
}