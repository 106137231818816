.hwx-lmdb-list-tiles {
    .hwx-lmdb-list-items {
        @include make-spacing(15);
        display   : flex;
        flex-wrap : wrap;

        @include media-breakpoint-only(xs) {
            justify-content : center;
        }

        .hwx-lmdb-list-item {
            max-width : 100%;

            @include media-breakpoint-up(sm) {
                max-width : 50%;
            }

            @include media-breakpoint-up(lg) {
                max-width : 25%;
            }

            .hwx-lmdb-list-item-date {
                @include padding-top(5);
                @include padding-bottom(5);
                text-align : center;
                border-top : 2px solid #000000;
            }

            .hwx-lmdb-list-item-image {

            }
        }
    }

    .hwx-lmdb-list-pagination {
        @include margin-top(50);

        ul.pagination {
            margin-bottom : 0;
        }
    }
}