.hwx-lmdb-basedetail {
    .hwx-lmdb-detail-item {
        .hwx-lmdb-detail-item-imageblock {
            @include margin-bottom(30);
            @include margin-left(60);
            float : right;
            width : 50%;

            @include media-breakpoint-only(xs) {
                float       : none;
                text-align  : center;
                margin-left : 0;
                width       : auto;
            }

            .figure {
                display : block;

                & + figure {
                    @include margin-top(15);
                }

                img {
                    width : 100%;
                }

                .figure-caption {
                    @include margin-top(5);
                    text-align : right;
                }
            }
        }

        .hwx-lmdb-detail-item-title {
            @include margin-bottom(30);

            .hwx-lmdb-detail-item-title-text {
                margin-bottom : 0;
            }
        }

        .hwx-lmdb-detail-item-details {
            @include make-horizontal-spacing($child-selector : span);
            @include margin-bottom(30);

            i,
            svg {
                @include margin-right(5);
            }

        }
    }
}