#hwx-lmdb-filter {
    @include margin-bottom(50);

    .hwx-lmdb-filter-label {

    }

    .hwx-lmdb-filter-button-container {

    }

    #hwx-lmdb-filter-category {
        .hwx-lmdb-filter-button-container {
            display   : flex;
            flex-wrap : wrap;

            button {
                @include margin-bottom(15);

                &:not(:last-child) {
                    @include margin-right(15);
                }
            }
        }
    }

    #hwx-lmdb-filter-location {

    }

    #hwx-lmdb-filter-date {

    }
}