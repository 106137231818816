$back-to-top-background                   : $primary !default;
$back-to-top-foreground                   : $secondary !default;
$back-to-top-top                          : auto !default;
$back-to-top-right                        : 20px !default;
$back-to-top-bottom                       : 100px !default;
$back-to-top-left                         : auto !default;
$grid-gutter-width                        : 30px !default;
$grid-gutter-width-half                   : $grid-gutter-width/2 !default;
$z-index                                  : () !default;
$z-index                                  : map-merge((
        "backgroundimage":0,
        "main-content":10,
        "header":200,
        "footer":100,
        "back-to-top":9000,
        "floating":9000,
        "cookiehint":9999,
), $z-index);
$base-font-size                           : 16px !default;
$template-font-family                     : "Work Sans" !default;
$template-font-path                       : "../fonts/WorkSans" !default;
$template-font-extensions                 : ttf !default;
$template-font-weights                    : Thin, ExtraLight, Light, Regular, Medium, SemiBold, Bold, ExtraBold, Black !default;
$template-font-italic                     : null !default;
$header-background-color                  : transparent !default;
$header-margin-top                        : 0 !default;
$header-margin-right                      : 0 !default;
$header-margin-bottom                     : 0 !default;
$header-margin-left                       : 0 !default;
$header-padding-top                       : 0 !default;
$header-padding-right                     : 0 !default;
$header-padding-bottom                    : 0 !default;
$header-padding-left                      : 0 !default;
$header-nav-background-color              : transparent !default;
$header-nav-link-color                    : $black !default;
$header-nav-link-font-weight              : $font-weight-bold !default;
$header-nav-link-font-size                : $base-font-size !default;
$header-nav-link-text-transform           : none !default;
$header-nav-link-line-height              : null !default;
$hwx-menu-home-icon-font-size             : 30px !default;
$hwx-menu-sub-menu-arrow-color            : $white !default;
$hwx-menu-multicol-ul-width               : 800px !default;
$hwx-menu-link-border-top-color           : $white !default;
$hwx-menu-link-border-right-color         : $white !default;
$hwx-menu-link-border-bottom-color        : $white !default;
$hwx-menu-link-border-left-color          : $white !default;
$mod-search-button-icon-font-size         : 25px !default;
$mod-search-button-margin-top             : 0 !default;
$mod-search-button-margin-right           : 0 !default;
$mod-search-button-margin-bottom          : 0 !default;
$mod-search-button-margin-left            : 0 !default;
$mod-search-button-padding-top            : 0 !default;
$mod-search-button-padding-right          : 0 !default;
$mod-search-button-padding-bottom         : 0 !default;
$mod-search-button-padding-left           : 0 !default;
$content-background-color                 : transparent !default;
$content-margin-top                       : 0 !default;
$content-margin-right                     : 0 !default;
$content-margin-bottom                    : 0 !default;
$content-margin-left                      : 0 !default;
$content-top-background-margin-top        : 100 !default;
$content-top-background-margin-right      : 0 !default;
$content-top-background-margin-bottom     : $content-top-background-margin-top !default;
$content-top-background-margin-left       : $content-top-background-margin-right !default;
$content-top-background-padding-top       : 0 !default;
$content-top-background-padding-right     : 0 !default;
$content-top-background-padding-bottom    : $content-top-background-padding-top !default;
$content-top-background-padding-left      : $content-top-background-padding-right !default;
$content-main-background-margin-top       : 100 !default;
$content-main-background-margin-right     : 0 !default;
$content-main-background-margin-bottom    : $content-main-background-margin-top !default;
$content-main-background-margin-left      : $content-main-background-margin-right !default;
$content-main-background-padding-top      : 0 !default;
$content-main-background-padding-right    : 0 !default;
$content-main-background-padding-bottom   : $content-main-background-padding-top !default;
$content-main-background-padding-left     : $content-main-background-padding-right !default;
$content-bottom-background-margin-top     : 100 !default;
$content-bottom-background-margin-right   : 0 !default;
$content-bottom-background-margin-bottom  : $content-bottom-background-margin-top !default;
$content-bottom-background-margin-left    : $content-bottom-background-margin-right !default;
$content-bottom-background-padding-top    : 0 !default;
$content-bottom-background-padding-right  : 0 !default;
$content-bottom-background-padding-bottom : $content-bottom-background-padding-top !default;
$content-bottom-background-padding-left   : $content-bottom-background-padding-right !default;
$footer-background-color                  : transparent !default;
$footer-margin-top                        : 0 !default;
$footer-margin-right                      : 0 !default;
$footer-margin-bottom                     : 0 !default;
$footer-margin-left                       : 0 !default;
$footer-padding-top                       : 0 !default;
$footer-padding-right                     : 0 !default;
$footer-padding-bottom                    : 0 !default;
$footer-padding-left                      : 0 !default;
$footer-nav-background-color              : transparent !default;
$footer-nav-link-color                    : $black !default;
$footer-nav-link-font-weight              : $font-weight-bold !default;
$footer-nav-link-font-size                : $base-font-size !default;
$footer-nav-link-text-transform           : none !default;
$footer-nav-link-line-height              : null !default;
$table-row-odd-color                      : $white !default;
$table-row-even-color                     : $gray-300 !default;
$letter-spacing                           : normal !default;