.hwx-social-buttons {
    .hwx-social-buttons-container {
        @include make-horizontal-spacing(5);
        display   : flex;
        flex-wrap : wrap;

        & > div {
            @include font-size-rem(24);
        }
    }
}