$white                 : #ffffff;
$gray-100              : #f8f9fa;
$gray-200              : #e9ecef;
$gray-300              : #dee2e6;
$gray-400              : #ced4da;
$gray-500              : #adb5bd;
$gray-600              : #6c757d;
$gray-700              : #495057;
$gray-800              : #343a40;
$gray-900              : #212529;
$black                 : #000000;
$facebook-blue         : #4267b2;
$facebook-white        : #ffffff;
$twitter-blue          : #1da1f2;
$twitter-white         : #ffffff;
$linkedin-blue         : #0a66c2;
$youtube-red           : #ff0000;
$youtube-white         : #ffffff;


$gray-template         : #9b9b9b;
$blue-template         : #0277ba;

//$primary                   : $dark-blue;
//$secondary                 : $beige;
//$link-color                : $primary;
//$navbar-light-color        : rgba($primary, .5);
//$navbar-light-hover-color  : rgba($primary, .7);
//$navbar-light-active-color : rgba($primary, .9);

$base-font-size        : 16px;
$nav-link-font-size    : $base-font-size;
$link-decoration       : underline;
$link-hover-decoration : underline;

$btn-border-radius     : 0;
$btn-border-radius-lg  : 0;
$btn-border-radius-sm  : 0;

//$navbar-padding-x      : 0;
$navbar-padding-y      : 0;