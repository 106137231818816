#back-to-top {
    background-color : #4cb7e9;
    border-radius    : 50%;
    z-index          : 0;

    svg {
        color : white;
    }
}

.modal-backdrop {

}


header#header {
    .header-top {
        width : 100%;

        .header-top-container {
            width : 100%;

            .logo-contact-row {

                @include media-breakpoint-down(md) {
                    height : 125px;
                }
                @include media-breakpoint-down(sm) {
                    height : unset;
                }

                .logo-col {
                    position : relative;
                    // Company Logo left

                    @include media-breakpoint-down(sm) {
                        margin-bottom : 45px;
                    }

                    img {
                        width : 80%;

                        @include media-breakpoint-up(md) {
                            position : absolute;
                            top      : 0;
                            bottom   : 0;
                            margin   : auto;
                            width    : unset;
                        }
                    }
                }

                // Contact and Logo right
                .logo-contact-col {
                    position : relative;

                    .logos-contact-row {

                        div.contact-col {
                            margin-bottom : 80px;


                            @include media-breakpoint-down(md) {
                                display : none;

                            }

                            div.phone-container {
                                width         : max-content;
                                margin        : 0;
                                text-align    : right;
                                padding-right : 0;

                                @include media-breakpoint-up(md) {
                                    margin        : auto 0 auto auto;
                                    padding-right : 20px;
                                }

                                .phone {
                                    position        : relative;
                                    text-decoration : none;
                                    color           : #787878;
                                    font-size       : 0.9rem;
                                    margin-left     : -25px;

                                    @include media-breakpoint-up(md) {
                                        font-size : 1.6rem;
                                    }

                                    img {
                                        height       : 25px;
                                        margin-right : 12px;

                                        @include media-breakpoint-up(md) {
                                            position : absolute;
                                            left     : -30px;
                                            height   : 25px;
                                            bottom   : 4px;
                                        }
                                    }
                                }
                            }

                            div.email-container {
                                width      : max-content;
                                margin     : auto 0 auto auto;
                                text-align : center;

                                @include media-breakpoint-up(md) {
                                    text-align    : right;
                                    padding-right : 0;
                                }

                                .email {
                                    position        : relative;
                                    text-decoration : none;
                                    color           : $blue-template;
                                    font-size       : 0.9rem;

                                    @include media-breakpoint-up(md) {
                                        font-size     : 1.1rem;
                                        padding-right : 8px;
                                    }

                                    img {
                                        height       : 25px;
                                        margin-right : 8px;

                                        @include media-breakpoint-up(md) {
                                            position : absolute;
                                            left     : -32px;
                                            height   : 20px;
                                            bottom   : 0;
                                        }
                                    }
                                }
                            }

                        }

                        .logos-col {
                            position      : absolute;
                            bottom        : 12px;
                            text-align    : right;
                            padding-right : 0;

                            @include media-breakpoint-down(md) {
                                text-align : center;
                            }

                            img {
                                width : unset;

                                @include media-breakpoint-down(md) {
                                    width   : 80%;
                                    padding : 0 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header-nav-top {

    }

    .header-main {

    }

    .header-nav-bottom {
        background : #0277ba;

        #module-nav-104 {
            div.button-container {
                button {
                    border-color : rgb(255, 255, 255);
                    margin       : 4px 0;

                    svg {
                        path {
                            color : white;
                        }
                    }
                }
            }

            div#navbar-104 {

                .navbar-nav {
                    margin : auto;
                    width  : max-content;

                    .active > a {
                        background-color : #4cb7e9;
                    }

                    .active ul li a {
                        background-color : unset;
                    }


                    .first-item {
                        background-color : unset;

                        @include media-breakpoint-up(lg) {
                            margin-right : 20px;
                        }
                    }


                    li {
                        padding : 0;
                        width   : 100%;

                        /* Style the rest of the page. */
                        body {
                            background-color : #fcfdfc;
                        }

                        a {
                            padding     : 8px 16px;
                            margin-left : 1px;
                            margin-top  : 1px;

                            @include media-breakpoint-up(lg) {
                                background-image    : linear-gradient(60deg, #fff0 50%, #4cb7e9 50%);
                                background-size     : 300%;
                                background-repeat   : repeat;
                                background-position : top right;
                                animation           : button-animation 0.1ms 0.15s ease-out forwards;
                                display             : table;
                                text-decoration     : none;
                                cursor              : pointer;

                            }

                            .sub-arrow {
                                display : none;
                            }

                            span {
                                color                   : white;
                                -webkit-background-clip : text;
                                font-weight             : normal;
                                font-size               : 1rem;
                                text-align              : center;
                                margin-bottom           : 0;
                                margin-bottom           : -0.25em;
                                width                   : 100%;
                            }
                        }

                        a:hover, a:hover span {
                            animation : button-animation-rev 0.65s ease-out forwards;
                        }

                        @keyframes button-animation {
                            0% {
                                background-position : top right;
                            }
                            100% {
                                background-position : top left;
                            }
                        }

                        @keyframes button-animation-rev {
                            0% {
                                background-position : top left;
                            }
                            100% {
                                background-position : top right;
                            }
                        }

                    }


                    li.dropdown {

                        ul {
                            padding : 0;
                            @include media-breakpoint-down(lg) {
                                display : unset;
                            }

                            li:first-child {
                                @include media-breakpoint-up(lg) {

                                }

                                a {
                                    padding-bottom : 2px;
                                    margin         : 0;
                                }
                            }

                            li:last-child {
                                @include media-breakpoint-up(lg) {

                                }

                                a {
                                    padding-bottom : 2px;
                                }
                            }

                            li {
                                a {
                                    padding-top    : 0;
                                    padding-bottom : 0;
                                    display        : block;
                                    padding-left   : 30px;

                                    @include media-breakpoint-up(lg) {
                                        padding-top    : 2px;
                                        padding-bottom : 2px;
                                        background     : white;
                                        padding-left   : 16px;
                                    }

                                    span {
                                        font-weight : 300;

                                        @include media-breakpoint-up(lg) {
                                            color       : #0277ba;
                                            font-weight : normal;
                                        }
                                    }
                                }

                                a:hover {
                                    @include media-breakpoint-up(lg) {
                                        background : #0277ba;
                                        transition : all 0.5s;
                                    }

                                    span {
                                        @include media-breakpoint-up(lg) {
                                            color      : white;
                                            transition : all 1s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header-bottom {

    }
}