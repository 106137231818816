.hwx-lmdb-events-module-tiles {
    @include make-spacing(15);
    display   : flex;
    flex-wrap : wrap;

    @include media-breakpoint-only(xs) {
        justify-content : center;
    }

    .hwx-lmdb-event {
        max-width : 100%;

        @include media-breakpoint-up(sm) {
            max-width : 50%;
        }

        @include media-breakpoint-up(lg) {
            max-width : 25%;
        }

        .hwx-lmdb-event-date {
            @include padding-top(5);
            @include padding-bottom(5);
            text-align : center;
            border-top : 2px solid #000000;
        }
    }
}