.hwx-footer {
    @include padding-top(50);
    @include padding-bottom(50);

    p:last-of-type {
        margin-bottom : 0;
    }

    .row {
        @include media-breakpoint-only(xs) {
            @include make-vertical-spacing($grid-gutter-width-half);
        }

        @include media-breakpoint-up(sm) {
            @include make-vertical-spacing($grid-gutter-width);
        }
    }
}