#hwx-lmdb-termine-list {
    &.hwx-lmdb-list-default {
        .hwx-lmdb-list-items {
            .hwx-lmdb-list-item {
                display : flex;

                .hwx-lmdb-list-item-date {
                    align-self : flex-start;
                    flex       : 0 0 100%;
                }

                .hwx-lmdb-list-item-content {
                    flex : 1;
                }

                .hwx-lmdb-list-item-image {
                    flex : 0 1 100%;
                }
            }
        }
    }
}