.hwx-lmdb-list-baselist {
    .hwx-lmdb-list-items {
        @include make-vertical-spacing();

        .hwx-lmdb-list-item {
            @include make-horizontal-spacing();

            .hwx-lmdb-list-item-date {
                @include max-width(80);

                & > div {
                    @include padding-top(10);
                    @include padding-bottom(10);
                    @include font-size-rem(18);
                    color            : #212529;
                    background-color : #f2efed;
                    line-height      : 1;
                    font-weight      : bold;
                    text-align       : center;
                }
            }

            .hwx-lmdb-list-item-content {
                .hwx-lmdb-list-item-content-title {
                    @include margin-bottom(5);

                    .hwx-lmdb-list-item-content-title-text {
                        color         : #d10019;
                        margin-bottom : 0;
                    }
                }

                .hwx-lmdb-list-item-content-details {
                    @include margin-bottom(15);
                    @include make-horizontal-spacing($child-selector : span);

                    i,
                    svg {
                        margin-right : 5px;
                    }
                }

                .hwx-lmdb-list-item-content-text {
                    @include margin-top(15);
                    margin-top : 15px;
                }
            }

            .hwx-lmdb-list-item-image {
                @include media-breakpoint-up(md) {
                    @include max-width(270);
                }
            }
        }
    }

    .hwx-lmdb-list-pagination {
        @include margin-top(50);

        ul.pagination {
            margin-bottom : 0;
        }
    }
}