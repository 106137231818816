div#background-container {
    @include zindex(backgroundimage);
    position : fixed;
    width    : 100%;
    height   : 100%;
    top      : 0;
    left     : 0;
}

main#content {
    // if hwx-sticky-top or hwx-sticky-bottom is added as class to footer or header, margin-bottom and top is overwritten via javascript
    @include rem-multivalue(margin, $content-margin-top, $content-margin-right, $content-margin-bottom, $content-margin-left);
    @include zindex(main-content);
    background-color : $content-background-color;
    position         : relative;

    #content-banner-top,
    #content-banner-bottom {
        img {
            width : 100%;
        }

        p {
            margin : 0;
        }
    }

    .content-top-background {
        @include margin($content-top-background-margin-top / 4, $content-top-background-margin-right / 4, $content-top-background-margin-bottom / 4, $content-top-background-margin-left / 4);
        @include padding($content-top-background-padding-top / 4, $content-top-background-padding-right / 4, $content-top-background-padding-bottom / 4, $content-top-background-padding-left / 4);

        @include media-breakpoint-up(sm) {
            @include margin($content-top-background-margin-top / 2, $content-top-background-margin-right / 2, $content-top-background-margin-bottom / 2, $content-top-background-margin-left / 2);
            @include padding($content-top-background-padding-top / 2, $content-top-background-padding-right / 2, $content-top-background-padding-bottom / 2, $content-top-background-padding-left / 2);
        }

        @include media-breakpoint-up(lg) {
            @include margin($content-top-background-margin-top, $content-top-background-margin-right, $content-top-background-margin-bottom, $content-top-background-margin-left);
            @include padding($content-top-background-padding-top, $content-top-background-padding-right, $content-top-background-padding-bottom, $content-top-background-padding-left);
        }
    }

    .content-main-background {
        @include margin($content-main-background-margin-top / 4, $content-main-background-margin-right / 4, $content-main-background-margin-bottom / 4, $content-main-background-margin-left / 4);
        @include padding($content-main-background-padding-top / 4, $content-main-background-padding-right / 4, $content-main-background-padding-bottom / 4, $content-main-background-padding-left / 4);

        @include media-breakpoint-up(sm) {
            @include margin($content-main-background-margin-top / 2, $content-main-background-margin-right / 2, $content-main-background-margin-bottom / 2, $content-main-background-margin-left / 2);
            @include padding($content-main-background-padding-top / 2, $content-main-background-padding-right / 2, $content-main-background-padding-bottom / 2, $content-main-background-padding-left / 2);
        }

        @include media-breakpoint-up(lg) {
            @include margin($content-main-background-margin-top, $content-main-background-margin-right, $content-main-background-margin-bottom, $content-main-background-margin-left);
            @include padding($content-main-background-padding-top, $content-main-background-padding-right, $content-main-background-padding-bottom, $content-main-background-padding-left);
        }

        #content-right {
            @include media-breakpoint-down(sm) {
                @include margin-top(50);
            }
        }
    }

    .content-bottom-background {
        @include margin($content-bottom-background-margin-top / 4, $content-bottom-background-margin-right / 4, $content-bottom-background-margin-bottom / 4, $content-bottom-background-margin-left / 4);
        @include padding($content-bottom-background-padding-top / 4, $content-bottom-background-padding-right / 4, $content-bottom-background-padding-bottom / 4, $content-bottom-background-padding-left / 4);

        @include media-breakpoint-up(sm) {
            @include margin($content-bottom-background-margin-top / 2, $content-bottom-background-margin-right / 2, $content-bottom-background-margin-bottom / 2, $content-bottom-background-margin-left / 2);
            @include padding($content-bottom-background-padding-top / 2, $content-bottom-background-padding-right / 2, $content-bottom-background-padding-bottom / 2, $content-bottom-background-padding-left / 2);
        }

        @include media-breakpoint-up(lg) {
            @include margin($content-bottom-background-margin-top, $content-bottom-background-margin-right, $content-bottom-background-margin-bottom, $content-bottom-background-margin-left);
            @include padding($content-bottom-background-padding-top, $content-bottom-background-padding-right, $content-bottom-background-padding-bottom, $content-bottom-background-padding-left);
        }
    }
}