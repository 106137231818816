.hwx-parallaxcontent {
    @include padding-top(75);
    @include padding-bottom(75);
    position : relative;

    & > a {
        text-decoration : none;
        color           : $black;
    }

    &.hwx-parallaxcontent-image-right {
        .hwx-parallaxcontent-image {
            order : 1;
        }
    }

    .hwx-parallax-item {
        will-change : transform;
    }

    .hwx-parallaxcontent-background-container {
        position : absolute;
        top      : 0;
        left     : 0;
        right    : 0;
        height   : 100%;
        width    : 100%;

        .hwx-parallaxcontent-background {
            background-position : center;
            background-size     : cover;
            height              : 100%;
        }
    }

    .hwx-parallaxcontent-container {
        display        : flex;
        flex-direction : column;

        @include media-breakpoint-down(sm) {
            @include make-vertical-spacing($grid-gutter-width-half);
        }

        @include media-breakpoint-up(md) {
            @include make-horizontal-spacing($grid-gutter-width-half);
            flex-direction : row;
        }

        .hwx-parallaxcontent-image,
        .hwx-parallaxcontent-content {
            @include media-breakpoint-up(md) {
                flex : 1 0 50%;
            }
        }

        .hwx-parallaxcontent-image {
            @include media-breakpoint-down(sm) {
                order : 0;
            }

            img {
                @include media-breakpoint-down(sm) {
                    width : 100%;
                }
            }
        }

        .hwx-parallaxcontent-content {
            display     : flex;
            align-items : center;

            @include media-breakpoint-down(sm) {
                order : 1;
            }

            p:last-of-type {
                margin-bottom : 0;
            }
        }
    }
}