.hwx-item-container {
    &.hwx-image-float {
        .hwx-item-body {
            @include clearfix;
            display : block;

            .hwx-item-imageblock {
                @include margin-bottom(30);

                @include media-breakpoint-up(lg) {
                    float : right;
                }

                @include media-breakpoint-only(lg) {
                    max-width : 50%;
                }

                @include media-breakpoint-up(xl) {
                    max-width : 40%;
                }
            }

            .hwx-item-introtext {
                @include media-breakpoint-only(lg) {
                    @include padding-right(40);
                }

                @include media-breakpoint-up(xl) {
                    @include padding-right(60);
                }
            }
        }
    }

    .hwx-item-body {
        display : flex;

        .hwx-item-imageblock,
        .hwx-item-introtext {
            flex : 1;

            @include media-breakpoint-only(lg) {
                flex-basis : 50%;
                max-width  : 50%;
            }
        }


        .hwx-item-imageblock {
            text-align : center;
            order      : 1;

            @include media-breakpoint-up(xl) {
                flex-basis : 40%;
                max-width  : 40%;
            }
        }

        .hwx-item-introtext {
            order : 0;

            @include media-breakpoint-up(xl) {
                flex-basis : 60%;
                max-width  : 60%;
            }

            > p {
                &:last-child {
                    margin-bottom : 0;
                }
            }

            img {
                @extend .img-fluid;
            }
        }
    }

    .hwx-item-gallery {
        @include margin-top(50);
    }
}