.hwx-logoslider {
    @include padding(30);

    .hwx-module-header {
        .hwx-module-header-text {
            @include margin-bottom(30);
            text-align : center;
        }
    }

    .owl-carousel {
        .owl-stage {
            display     : flex;
            align-items : center;

            .owl-item {
                img {
                    width        : auto !important;
                    margin-left  : auto;
                    margin-right : auto;
                }
            }
        }
    }

    .hwx-logoslider-static-items {
        @include make-spacing($grid-gutter-width-half);
        display         : flex;
        justify-content : center;
        align-items     : center;
    }
}