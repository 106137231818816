
h1 {
    font-size : 3rem;
}

h2, h3 {
    margin-top : 30px;
}

div.ug-item-wrapper {
    background : #eeeeee;
}

div#partner-logos {
    img {
        max-height : 100px;
        object-fit : contain;
        border     : 1px solid #eaeaea;
        padding    : 6px;
        box-shadow : unset;
        overflow   : unset;

        @include media-breakpoint-down(md) {
            margin-left  : unset !important;
            margin-right : unset !important;
        }
    }

    p {
        width : max-content;
    }

    h6 {
        background : #f4f4f4;
        padding    : 3px 8px;
    }
}

.hausverwaltung, .bestand {
    #gallery {
        .ug-textpanel {
            display : none !important;
        }
    }
}

#contact-tel-mail-cont {
    display         : flex;
    flex-direction  : column;
    align-items     : end;
    position        : fixed;
    top             : 30%;
    right           : 0;
    width           : inherit;
    text-align      : right;
    justify-content : end;

    @include media-breakpoint-only(xs) {
        display : none;
    }

    div.tel, div.mail {
        background      : #4cb7e9;
        display         : flex;
        align-items     : center;
        justify-content : center;
        width           : 48px;
        height          : 48px;
        align-self      : flex-end;
        transition      : all 0.5s;
        margin          : 1px 0;

        svg {
            color     : white;
            font-size : 2.5rem;
            padding   : 8px;
        }

        a {
            display   : none;
            color     : #4cb7e900;
            font-size : 0;
        }
    }

    div.tel:hover, div.mail:hover {
        background         : #3fb8e4;
        border-top         : 2px solid white;
        border-bottom      : 2px solid white;
        width              : 220px;
        transition         : all 0.5s;
        text-align         : left;
        -webkit-box-shadow : inset 0 0 10px #0277ba;

        svg {
            background : #0277ba;
        }

        a {
            display         : block;
            width           : 500px;
            text-decoration : none;
            color           : white;
            text-align      : center;
            font-weight     : 300;
            font-size       : 1rem;
        }

    }

}

.hwx-item-container .hwx-item-body .hwx-item-introtext {
    max-width    : unset;
    margin-right : 25px;
    color        : #616161;
    font-weight  : 300;
}

.front {
    main#content {
        div.content-main-background {
            display : none;
        }

        div#k2ModuleBox97 {
            .row {
                div.k2ItemsBlock-col {
                    div.moduleItemIntrotext {
                        height : 112%;

                        .moduleItemImage {
                            div.circular {
                                background : unset !important;


                                img {
                                    height     : 300px !important;
                                    object-fit : contain !important;
                                    padding    : 18px 18px 0 !important;
                                }
                            }
                        }

                        .moduleItemTitle-container {
                            padding : 0 18px;
                        }

                        .moduleItemIntrotext-container {
                            padding : 0 18px;
                        }

                        .hwx-itemlist-readmore {
                            position        : absolute !important;
                            bottom          : 10px !important;
                            text-decoration : none !important;
                            padding         : 0 18px;

                        }
                    }
                }
            }
        }

    }
}

body.referenzen-2 {
    div#k2Container {
        div.itemListCategoriesBlock {
            margin-bottom : 30px;

            div.itemListCategory {
                border-bottom : 2px solid #4cb7e9;

                h1 {
                    width            : max-content;
                    background-color : #4cb7e9;
                    color            : white;
                    padding          : 6px 22px;
                    margin-bottom    : 0;
                    font-size        : 1.75rem;
                }

                p {
                    margin-bottom : 0;
                }
            }
        }
    }
}

body.leistungen, body.wohnen, body.sonstiges, body.unternehmen {
    .main-content-container {
        .hwx-category-container {
            .hwx-category-body {
                .hwx-category-item-container {
                    height : 100%;

                    .hwx-category-item-body {
                        .hwx-itemlist-imageblock {
                            .hwx-imageset {
                                figure {
                                    width : 100%;

                                    a {
                                        img {
                                            height             : 250px;
                                            width              : 100% !important;
                                            -o-object-fit      : cover;
                                            object-fit         : cover;
                                            -o-object-position : center;
                                            object-position    : center;
                                            padding            : 18px 18px 0;

                                            @media (min-width : 1600px) {
                                                height : 320px;

                                            }

                                            @media (min-width : 2000px) {
                                                height : 350px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .hwx-itemlist-header {
                            padding : 0 1.25rem 1.25rem;

                            .hwx-itemlist-header-text {
                                margin-bottom : 0;

                                a {
                                    font-size : 1.25rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-main-background {
        div.container-fluid, div.container {


            div.main-content-container {

                @include media-breakpoint-down(lg) {
                    padding : 0;
                }

                div#hwx-default-item {
                    div.hwx-item-header {
                        border-bottom : 2px solid #4cb7e9;

                        .hwx-item-header-text {
                            width            : max-content;
                            background-color : #4cb7e9;
                            color            : white;
                            padding          : 6px 22px;
                            margin-bottom    : 0;
                            font-size        : 1.75rem;
                            font-weight      : normal;
                        }
                    }
                }
            }
        }
    }
}


main#content {

    p, span, h1, h2, h3, h4, h5, h6, .news-text-wrap, strong, li {
        color : #616161;
    }

    a:hover {
        color           : #337ab7;
        text-decoration : underline;
    }

    a {
        color           : #337ab7;
        text-decoration : none;
    }

    #content-banner-top {
        div#hwx-carousel-105 {
            div.owl-carousel {
                div.owl-stage-outer {
                    div.owl-stage {
                        div.owl-item {
                            div.item {
                                div.hwx-carousel-image {
                                    img {
                                        height             : 500px;
                                        width              : 100% !important;
                                        -o-object-fit      : contain;
                                        object-fit         : contain;
                                        -o-object-position : top center;
                                        object-position    : center center;

                                        background         : linear-gradient(90deg, rgb(233, 233, 233) 0%, rgb(230, 230, 230) 100%);

                                        @include media-breakpoint-down(md) {
                                            height : 136px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-top-background {
        #content-top {
            div.k2ItemsBlock {
                margin-bottom : 100px;

                @include media-breakpoint-down(md) {
                    margin-bottom : 50px;
                }

                div.row {
                    margin-left  : 0;
                    margin-right : 0;
                }

                div.hwx-k2ItemsBlock-header-block {
                    position      : relative;
                    margin-bottom : 56px;

                    @include media-breakpoint-up(md) {
                        margin-bottom : 30px;
                    }

                    div.hwx-k2ItemsBlock-header {
                        border-bottom : 2px solid #4cb7e9;

                        .hwx-k2ItemsBlock-header-text {
                            width            : max-content;
                            background-color : #4cb7e9;
                            color            : white;
                            padding          : 6px 22px;
                            margin-bottom    : 0;
                        }
                    }

                    div.itemCustomLink {
                        position : absolute;
                        bottom   : -85%;

                        @include media-breakpoint-up(md) {
                            top   : 25%;
                            right : 0;
                        }

                        a {
                            text-decoration : none;
                            color           : #4cb7e9;
                        }

                        a:hover {
                            color           : #0056b3;
                            text-decoration : underline;
                        }

                        a:after {
                            content : ' >';
                        }
                    }
                }

                div.k2ItemsBlock-row {
                    margin-left  : 0 !important;
                    margin-right : 0 !important;


                    div.k2ItemsBlock-col {
                        @include media-breakpoint-down(md) {
                            margin-bottom : 65px;
                        }

                        div.moduleItemIntrotext-container {
                            color       : #616161;
                            font-weight : lighter;
                        }

                        div.moduleItemIntrotext {
                            a {
                                img {
                                    height             : 250px;
                                    width              : 100% !important;
                                    -o-object-fit      : cover;
                                    object-fit         : cover;
                                    -o-object-position : top center;
                                    object-position    : center center;
                                }
                            }
                        }

                        div.moduleItemTitle-container {
                            margin : 25px 0 20px;

                            @include media-breakpoint-up(md) {
                                margin : 25px 0 35px;
                            }

                            .moduleItemTitle {
                                text-decoration : none;
                            }

                            .moduleItemTitle:hover {
                                color           : #0056b3;
                                text-decoration : underline;
                            }
                        }

                        div.hwx-itemlist-readmore {
                            position        : absolute;
                            bottom          : -25px;
                            text-decoration : none;

                            @include media-breakpoint-down(md) {
                                bottom : -35px;
                            }

                            a {
                                text-decoration : unset;
                                font-size       : 1.1rem;
                            }
                        }


                        div.hwx-itemlist-readmore:hover {
                            color           : #0056b3;
                            text-decoration : underline;
                        }
                    }
                }

            }
        }

        div#aktuelle-immobilien {
            padding             : 50px 0;
            background-image    : url("../../../images/bg_angebote_ohne_klinke.jpg");
            background-color    : #0277ba;
            background-position : center;
            background-repeat   : no-repeat;
            background-size     : cover;


            div.immobilien-row-title {
                margin-bottom : 56px;
                position      : relative;

                @include media-breakpoint-up(md) {
                    margin-bottom : 30px;
                }

                div.module-hl {
                    a.all-immo-link {
                        text-decoration: none;
                    }
                    div.font-24-border {
                        @include media-breakpoint-up(md) {
                            border-bottom : 2px solid #4cb7e9;
                        }

                        .font-24 {
                            width            : max-content;
                            background-color : #4cb7e9;
                            color            : white;
                            padding          : 6px 22px;
                            margin-bottom    : 0;

                            @include media-breakpoint-down(md) {
                                font-size : 1.2rem;
                            }
                        }
                    }

                    div.itemCustomLink {
                        position : absolute;
                        bottom   : -85%;
                        width    : max-content;

                        @include media-breakpoint-up(md) {
                            right  : 20px;
                            bottom : 15%;
                        }

                        a.immo-link {
                            text-decoration : none;
                            color           : #4cb7e9;
                        }

                        a.immo-link:hover {
                            color           : #0056b3;
                            text-decoration : underline;
                        }

                        a.immo-link:after {
                            content : ' >';
                        }
                    }
                }
            }

            div.immobilien-row-title {
                margin-left  : 0;
                margin-right : 0;
            }

            div.immobilien-row-content {
                margin-right : 0;
                margin-left  : 0;

                div.immobilien-col {

                    @include media-breakpoint-down(md) {
                        margin-bottom : 35px;
                    }

                    div.immobilienvorschau {
                        height      : 100%;
                        font-weight : lighter;

                        div.immobilien-card {
                            height : 100%;

                            .image-viewport {
                                text-align : center;
                            }

                            .p4-full-blue {
                                background : #0277bb;
                                color      : white;
                            }

                            .p4-light-blue {
                                background : #3fb8e4;
                                color      : white;
                            }

                            .p4-full-blue-font {
                                color : #0277bb;
                                color : white;
                            }

                            .font-14 {
                                font-size   : 14px;
                                font-weight : 400;
                            }

                            .obj-img-overlay {
                                position : absolute;
                                z-index  : 10;
                                top      : 0;
                                left     : 0;
                                padding  : 2px 15px;
                            }

                            div.image-viewport {
                                img {
                                    height             : 195px;
                                    -o-object-fit      : cover;
                                    object-fit         : cover;
                                    -o-object-position : top center;
                                    object-position    : center center;
                                }
                            }

                            div.immobilien-card-body {
                                .card-title {
                                    color         : #0056b3;
                                    margin-top    : 4px;
                                    margin-bottom : 24px;
                                }

                                .card-text {
                                    color         : #616161;
                                    margin-bottom : 20px;
                                }

                                a.readmore-pos-abs {
                                    text-decoration : unset;
                                    position        : absolute;
                                    bottom          : 6px;
                                    text-decoration : none;
                                    font-size       : 1.1rem;
                                }

                                a.readmore-pos-abs:hover {
                                    color           : #0056b3;
                                    text-decoration : underline;
                                }
                            }
                        }
                    }
                }

                div.immobilien-col:nth-child(1) {
                    @include media-breakpoint-up(lg) {
                        padding-left  : 85px;
                        padding-right : 15px;
                    }
                }

                div.immobilien-col:nth-child(2) {
                    @include media-breakpoint-up(lg) {
                        padding-right : 15px;
                        padding-left  : 15px;
                    }

                    div.immobilienvorschau {
                        @include media-breakpoint-up(lg) {
                            width  : 80%;
                            margin : auto;
                        }
                    }
                }

                div.immobilien-col:nth-child(3) {
                    @include media-breakpoint-up(lg) {
                        padding-right : 85px;
                        padding-left  : 15px;
                    }
                }
            }
        }
    }

    .content-main-background {
        div.container-fluid, div.container {


            div.main-content-container {

                @include media-breakpoint-down(lg) {
                    padding : 0;
                }

                div#lwi-filter {
                    div.hwx-itemlist-category-header {
                        border-bottom : 2px solid #4cb7e9;
                        margin-bottom : 65px;

                        .hwx-itemlist-category-header-text {
                            width            : max-content;
                            background-color : #4cb7e9;
                            color            : white !important;
                            padding          : 6px 22px;
                            margin-bottom    : 0;

                        }
                    }
                }

                div#hwx-default-item {
                    div.hwx-item-header {


                        .hwx-item-header-text {

                        }
                    }

                    div.hwx-item-body {
                        div.hwx-item-image {

                            div.hwx-item-imageblock {
                                max-width : unset;

                                div.hwx-item-image {
                                    a {
                                        img {
                                            width : 100%;
                                        }
                                    }
                                }
                            }
                        }

                        div.hwx-item-introtext {
                            max-width    : unset !important;
                            margin-right : 25px;
                            color        : #616161;
                            font-weight  : 300;

                            div.hwx-item-introtext {
                                color        : #222121;
                                font-weight  : 300 !important;
                                max-width    : unset !important;
                                margin-right : 25px;
                                color        : #616161;
                                font-weight  : 300;

                                div.services-row {
                                    div.services-col {
                                        div.services-card {
                                            a {
                                                img {
                                                    height             : 250px;
                                                    width              : 100% !important;
                                                    -o-object-fit      : cover;
                                                    object-fit         : cover;
                                                    -o-object-position : top center;
                                                    object-position    : top center;
                                                    padding            : 18px 18px 0;
                                                }
                                            }

                                            div.services-body {
                                                div.services-title {
                                                    margin-bottom : 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                div#immo-detail {
                    font-weight : 300;
                    color       : #616161;

                    #hwx-gallery {

                        div.ug-strip-panel {
                            background-color : #eeeeee;
                        }
                    }

                    div.immo-detail-row {

                        div.immo-detail-images {

                            div#carousel-P4-Immo {
                                margin-bottom : 80px;

                                .carousel-control-prev, .carousel-control-next {
                                    z-index : 0;
                                }

                                div.carousel-inner {
                                    height : 325px;
                                    width  : 100%;

                                    div.modal {
                                        background : #000c;

                                        div.modal-dialog {
                                            div.modal-content {

                                                img {
                                                    border : 3px solid white;
                                                }

                                                .close {
                                                    position : absolute;
                                                    top      : 10px;
                                                    right    : 20px;

                                                    span {
                                                        font-size     : 45px;
                                                        color         : black;
                                                        padding-left  : 16px;
                                                        padding-right : 16px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    div.carousel-item {


                                        img {
                                            height             : 325px;
                                            width              : 100% !important;
                                            -o-object-fit      : cover;
                                            object-fit         : cover;
                                            -o-object-position : center;
                                            object-position    : center;
                                        }
                                    }
                                }

                                .carousel-indicators {
                                    width           : 100%;
                                    margin          : 0;
                                    bottom          : -85px;
                                    right           : unset;
                                    overflow-x      : scroll;
                                    display         : flex;
                                    flex-direction  : row;
                                    justify-content : flex-start;
                                    background      : #eeeeee;
                                    display         : flex;
                                    overflow-y      : hidden;
                                    overflow-x      : scroll;
                                    z-index         : 1;

                                    @include media-breakpoint-down(lg) {
                                        bottom : -70px;
                                    }

                                    a:first-child {
                                        img:first-child {

                                        }
                                    }

                                    a {
                                        padding-right : 2px;

                                        img {
                                            height             : 65px;
                                            width              : 85px !important;
                                            -o-object-fit      : cover;
                                            object-fit         : cover;
                                            -o-object-position : center;
                                            object-position    : center;
                                        }
                                    }
                                }
                            }

                            div.immo-preview-gallery {
                                background-color : #eeeeee;
                            }
                        }

                        div.immo-detail-content-row {
                            .text-underline {
                                @include media-breakpoint-down(sm) {
                                    text-decoration : underline;
                                }
                            }
                        }

                        div.immo-detail-baseurl {
                            a {
                                color           : #7b7b7b;
                                text-decoration : none;
                            }
                        }

                        div.immo-detail-title {
                            h3 {
                                padding-bottom : 20px;
                                margin-top     : 20px;
                                margin-bottom  : 10px;
                                font-size      : 24px;
                                color          : #616161;
                                font-weight    : 500;
                                line-height    : 1.1;
                            }
                        }

                        div.item-object-details {
                            .text-underline {
                                @include media-breakpoint-down(sm) {
                                    text-decoration : underline;
                                }
                            }

                            div.item-row {
                                font-size   : 16px;
                                margin-left : unset;
                                padding     : 5px;
                                color       : #616161;
                                font-weight : 300;

                                @include media-breakpoint-up(lg) {
                                    margin-left : 10px;
                                }
                            }
                        }

                        div.p4-contact {
                            @include media-breakpoint-down(sm) {
                                order : 1;
                            }

                            div.p4-contact-image-content {
                                padding          : 15px;
                                background-color : #eeeeee;
                                height           : 100%;
                                font-size        : 18px;

                                div.p4-contact-image {
                                    img {
                                        max-width : 250px;
                                    }
                                }

                                div.p4-contact-content {
                                    p.p4-address-card {
                                        color       : #616161;
                                        font-weight : 300;
                                    }

                                    p.p4-phone-email-card {
                                        @include media-breakpoint-down(lg) {
                                            margin-left : 18px;
                                            width       : 100%;
                                        }

                                        span {
                                            svg {
                                                margin-right : 12px;
                                            }
                                        }

                                        .phone {
                                            color : #616161;
                                        }

                                        .email, .website {
                                            svg {
                                                fill : #337ab7;
                                            }

                                            a {
                                                text-decoration : none;
                                                color           : #337ab7;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                div#objektTable {
                    a {
                        color : #616161
                    }

                    div#lwi-filter {
                        div.objekt-filter-row {
                            div.filter {
                                background-color : #eeeeee;

                                div.input-group {
                                    div.input-group-prepend {
                                        width : 100%;

                                        .input-group-text {
                                            color       : #616161;
                                            font-weight : 300;
                                        }
                                    }

                                    .custom-select {
                                        color       : #616161;
                                        font-weight : 300;
                                    }
                                }
                            }
                        }


                        div.objekt-title {
                            h3 {
                                font-weight : 500;
                                line-height : 1.1;
                                color       : #746161;
                            }
                        }

                        div.objekt-content {
                            padding : 0;

                            div.objektShort {
                                position : relative;

                                @include media-breakpoint-down(md) {
                                    padding-bottom : 55px;
                                }

                                div.obj-details-link {
                                    position    : absolute;
                                    bottom      : 0;
                                    right       : 0;
                                    font-weight : 300;
                                }

                                a.rel {
                                    text-decoration : none !important;
                                    color           : #616161;

                                    div.obj_angaben {
                                        div.obj_title {
                                            span {
                                                font-size   : 1.1rem;
                                                font-style  : normal;
                                                font-weight : 600;
                                                line-height : 1.5em;
                                            }
                                        }

                                        div.obj_kurz {
                                            .no-wrap {
                                                font-weight : 300;
                                                font-size   : 16px;

                                                .p4-full-blue-font {
                                                    color : #0277bb;
                                                }
                                            }
                                        }
                                    }


                                    div.obj-img-container {
                                        position : relative;
                                        padding  : 0;

                                        div.obj-img {
                                            max-height : unset;
                                            height     : 200px;

                                            img {
                                                height          : 100%;
                                                object-fit      : contain;
                                                object-position : center center;
                                            }
                                        }

                                        .obj-img-overlay {
                                            position : absolute;
                                            top      : unset;
                                            right    : 0;
                                            bottom   : 20px;
                                            padding  : 0;

                                            @include media-breakpoint-down(md) {
                                                left   : 0;
                                                right  : unset;
                                                bottom : unset;
                                                top    : 0;
                                            }

                                            span {
                                                background : #3fb8e4;
                                                color      : #ffffff;
                                                font-size  : 14px;
                                                padding    : 2px 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }

                div.hwx-category-containernews, div.hwx-category-container {
                    div.hwx-categoryblock {
                        margin-bottom : 30px;

                        div.hwx-itemlist-category-header {
                            border-bottom : 2px solid #4cb7e9;

                            .hwx-itemlist-category-header-text {
                                width            : max-content;
                                background-color : #4cb7e9;
                                color            : white;
                                padding          : 6px 22px;
                                margin-bottom    : 0;

                            }
                        }
                    }

                    div.hwx-category-body {
                        div.hwx-category-item-containernews {
                            height : 112%;

                            div.hwx-category-item-body {
                                height : 100%;

                                div.hwx-itemlist-introtext {
                                    color       : #616161;
                                    font-weight : 300;
                                    padding     : 0 18px;
                                }

                                div.hwx-itemlist-header {
                                    margin : 25px 0 0;

                                    .hwx-itemlist-header-text {
                                        min-height : 65px;
                                        padding    : 0 18px;
                                    }
                                }

                                div.hwx-itemlist-imageblock {
                                    div.hwx-imageset {
                                        .figure {
                                            display : block;
                                            height  : 300px;

                                            a {
                                                img {
                                                    // height             : 195px;
                                                    height             : 100%;
                                                    width              : 100% !important;
                                                    // -o-object-fit      : cover;
                                                    // object-fit         : cover;
                                                    -o-object-fit      : contain;
                                                    object-fit         : contain;
                                                    -o-object-position : center;
                                                    object-position    : center;
                                                    padding            : 18px 18px 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            div.hwx-itemlist-readmore {
                                position        : absolute;
                                bottom          : 10px;
                                left            : 18px;
                                text-decoration : none;


                                a {
                                    text-decoration : unset;
                                    font-size       : 1.1rem;
                                }
                            }

                            div.hwx-itemlist-readmore:hover {
                                color           : #0056b3;
                                text-decoration : underline;
                            }
                        }
                    }
                }

                div.hwx-item-container {
                    div.hwx-item-body {
                        div.hwx-item-introtext {
                            margin-right : 25px;
                            color        : #616161;
                            font-weight  : 300;

                            div.hwx-item-introtext {
                                max-width    : unset !important;
                                margin-right : 25px;
                                color        : #616161;
                                font-weight  : 300;

                                .hwx-item-introtext-image-container {
                                    position : relative;


                                    .image-title-panel {
                                        position         : absolute;
                                        bottom           : -33px;
                                        opacity          : 0.6;
                                        background-color : rgb(0, 0, 0);
                                        width            : 100%;
                                        text-align       : left;

                                        p {
                                            margin-bottom : 0;
                                            color         : white;
                                            font-size     : 14px;
                                            font-weight   : 400;
                                            margin        : 6px 10px;
                                        }
                                    }
                                }
                            }
                        }

                        div.hwx-item-image {
                            div.hwx-item-imageblock {
                                max-width : unset;
                            }
                        }
                    }
                }
            }
        }
    }

    .content-bottom-background {
        #content-bottom {

        }
    }

    #content-banner-bottom {

        div#k2ModuleBox88 {
            margin-top : 50px;

            div.k2ItemsBlock-row {
                margin-left  : 0 !important;
                margin-right : 0 !important;

                div.k2ItemsBlock-col {
                    div.moduleItemIntrotext {
                        @include media-breakpoint-down(md) {
                            margin : 12px 0;
                        }

                        a.frontpage-itemlink {
                            div.circular {
                                display               : flex;
                                justify-content       : center;
                                align-items           : center;
                                width                 : 200px;
                                height                : 200px;
                                border-radius         : 100px;
                                -webkit-border-radius : 100px;
                                -moz-border-radius    : 100px;
                                overflow              : hidden;
                                margin                : 0 auto;
                                background-size       : cover !important;


                                img {
                                    height : 100%;
                                    width  : unset;
                                }

                                img:hover {
                                    height  : 100%;
                                    margin  : 0 auto;
                                    display : none;
                                }
                            }

                            div.circular-lupe {
                                display : none;
                            }
                        }

                        a.frontpage-itemlink:hover {
                            div.circular-lupe {
                                display               : block;
                                width                 : 200px;
                                height                : 200px;
                                border-radius         : 100px;
                                -webkit-border-radius : 100px;
                                -moz-border-radius    : 100px;
                                overflow              : hidden;
                                margin                : 0 auto;
                                background-size       : cover !important;
                                position              : absolute;
                                top                   : 0;
                                left                  : 0;
                                right                 : 0;

                                @include media-breakpoint-down(md) {
                                    top : 12px;
                                }
                            }
                        }
                    }

                    div.moduleItemTitle-container {
                        margin-top    : 20px;
                        margin-bottom : 14px;


                        h4 {
                            text-align : center;

                            a {
                                color : #4cb7e9;
                            }
                        }
                    }

                    div.moduleItemIntrotext-container {
                        color       : #616161;
                        text-align  : justify;
                        font-weight : lighter;

                        @include media-breakpoint-down(lg) {
                            padding : 0 16px;
                        }
                    }
                }
            }

            div.moduleItemIntrotextRow {
                list-style-type : none;
                margin-right    : 0 !important;
                margin-left     : 0 !important;

                div.moduleItemIntrotextCol {
                    display    : block;
                    width      : 25%;
                    float      : left;
                    background : transparent;
                    border     : 0;

                    .moduleItemTitle {
                        color         : #4abbec;
                        text-align    : center;
                        font-size     : 1.5em;
                        margin-top    : 18px;
                        margin-bottom : 18px;
                        font-weight   : 400;
                    }

                    div.moduleItemIntrotext {
                        display     : block;
                        padding     : 4px 10px;
                        line-height : 120%;
                        font-weight : 300;

                        .frontpage-itemlink {

                            div.circular {
                                width                 : 200px;
                                height                : 200px;
                                border-radius         : 100px;
                                -webkit-border-radius : 100px;
                                -moz-border-radius    : 100px;
                                overflow              : hidden;
                                margin                : 0 auto;
                                background-size       : cover !important;

                                img {
                                    height  : 100%;
                                    margin  : 0 auto;
                                    display : none;
                                }
                            }

                            .frontpage-itemtitle {
                                color         : #4abbec;
                                text-align    : center;
                                font-size     : 1.5em;
                                margin-top    : 18px;
                                margin-bottom : 18px;
                                font-weight   : 400;
                            }
                        }

                        .hyphenate {
                            p {
                                text-align : justify;
                            }
                        }

                        .moduleItemImage {
                            img {

                            }
                        }

                        p {

                        }
                    }
                }
            }
        }


        margin-bottom : 80px;

        div.custom {
            div.card-container {
                text-align : center;


                img {
                    boder-radius       : 50%;
                    height             : 200px;
                    width              : 200px;
                    -o-object-fit      : cover;
                    object-fit         : cover;
                    -o-object-position : top center;
                    object-position    : center center;
                    border-radius      : 50%;
                }
            }

            div.card-body {
                .card-title {
                    color : $blue-template;
                }

                p.card-text {
                    text-align  : justify;
                    font-weight : normal;
                    color       : $gray-template;
                }
            }
        }
    }
}

#hwx-gallery {
    .ug-slider-control {
        &:not(.ug-zoompanel) {
            //background-color : rgba(255, 255, 255, 0.5);
        }
    }

    .ug-zoompanel {
        .ug-zoompanel-button {
            &.ug-zoompanel-plus,
            &.ug-zoompanel-minus,
            &.ug-zoompanel-return {
                @include media-breakpoint-only(xs) {
                    display : none;
                }
            }
        }
    }
}